import React from 'react'
import { useEffect } from 'react';

import style from './parallax.module.css'

export const Parallax = () => {
  useEffect(()=>{
    const c = document.getElementById("c");
    const ctx = c.getContext("2d", { alpha: false });
    const positionInfo = c.getBoundingClientRect();

    c.height = positionInfo.height;
    c.width = positionInfo.width;


    var matrix = "1 0 1 0 1 0  1 0 0 0 0 1 0 1 1 1 0 1 1 0 1 0 1 0 0 1 0 0 0 1 0";
    matrix = matrix.split("");

    var font_size = 20;
    var columns = c.width/font_size;
    var drops = [];
    for(var x = 0; x < columns; x++)
        drops[x] = 1;

    function draw()
    {
        ctx.fillStyle = "rgba(0, 18, 41, 0.04)";
        ctx.fillRect(0, 0, c.width, c.height);

        ctx.fillStyle = "#68e1fd";
        ctx.font = font_size + "px arial";
        for(var i = 0; i < drops.length; i++)
        {
            var text = matrix[Math.floor(Math.random()*matrix.length)];
            ctx.fillText(text, i*font_size, drops[i]*font_size);
            if(drops[i]*font_size > c.height && Math.random() > 0.975)
                drops[i] = 0;
            drops[i]++;
        }
        requestAnimationFrame(draw);
    }
    requestAnimationFrame(draw);
  },[]);

  return (
    <section className={style.Parallax}>
      <div className={style.Parallax__background}>
        <canvas className={style.Parallax__canvas} id="c"></canvas>
      </div>
    </section>
  )
}

export default Parallax;
