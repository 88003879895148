import React from "react";
import "./assests/css/App.css";
import { Suspense } from "react";

import Parallax from "./components/Parallax";
import Header from "./components/Header";
const Footer = React.lazy(() => import("./components/Footer"));
const Statistics = React.lazy(() => import("./section/Statistics"));
const Hero = React.lazy(() => import("./section/Hero"));
const TypeER = React.lazy(() => import("./section/TypeER"));
const Registration = React.lazy(() => import("./section/Registration"));
const Features = React.lazy(() => import("./section/Features"));
const News = React.lazy(() => import("./section/News"));
const QA = React.lazy(() => import("./section/QA"));

export const SearchContext = React.createContext();
function App() {
  const [searchValue, setSearchValue] = React.useState("");

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1020);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1020;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/new-arrivals", {
        method: "GET",
        redirect: "follow",
      });
      if (response.ok) {
        const result = await response.json();
        setSearchValue(result);
      } else {
        console.log("Запрос с ошибкой");
      }
    };
    fetchData();
  }, []);

  return (
    <div className="App">
      {!isMobile ? <Parallax /> : ""}
      <Header />
      <main className="main">
        <Hero />
        <Suspense>
          <TypeER />
        </Suspense>
        <Suspense>
          <Registration />
        </Suspense>
        <Suspense>
          <Statistics />
        </Suspense>
        <Suspense>
          <SearchContext.Provider value={{ searchValue, setSearchValue }}>
            <Features />
            <News />
          </SearchContext.Provider>
        </Suspense>
        <Suspense>
          <QA />
        </Suspense>
      </main>
      <Suspense>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
